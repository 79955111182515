import { AxiosResponse } from 'axios';
import moment from 'moment-timezone';
const EST_TIMEZONE = 'America/New_York';

const Utils = {
    convertCase: function (text: string | undefined) {
        if (!text) return text;
        text = text.replace('_', ' ').toLowerCase();
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    },

    downloadFile: function (response: AxiosResponse, mimeType: string) {
        let blob = new Blob([response.data], {
                type: mimeType ? mimeType : 'text/csv',
            }),
            downloadUrl = window.URL.createObjectURL(blob),
            filename = '',
            disposition = response.headers['content-disposition'];

        if (disposition && disposition.indexOf('attachment') !== -1) {
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
                matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
        }

        let a = document.createElement('a');
        if (typeof a.download === 'undefined') window.location.href = downloadUrl;
        else {
            a.href = downloadUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
        }
    },

    setDefaultTimezone(timezone: string): void {
        moment.tz.setDefault(timezone);
    },

    toMidnightUTC(date: Date | undefined): Date {
        if (!date) date = new Date();
        date.setUTCHours(0, 0, 0, 0);
        return date;
    },

    toAbsoluteUTC(date: Date): Date {
        return moment.utc(moment(date).format('YYYY-MM-DD HH:mm:ss')).toDate();
    },

    toStartOfDayUTC(date: Date): Date {
        return moment.utc(moment(date).startOf('day').add(8, 'hours').format('YYYY-MM-DD HH:mm:ss')).toDate();
    },

    toEndOfDayUTC(date: Date): Date {
        return moment.utc(moment(date).endOf('day').format('YYYY-MM-DD HH:mm:ss')).toDate();
    },

    formatDateLong(dateInput: Date | undefined | null): string {
        if (!dateInput) return '';
        if (Array.isArray(dateInput)) dateInput = Utils.getDateByArray(dateInput);
        return moment(dateInput).format('MMM DD YYYY');
    },

    formatStringToDate(dateInput: string | undefined | null): Date {
        return moment(dateInput).tz(EST_TIMEZONE).toDate();
    },

    formatDateTime(dateInput: Date | undefined | null, format?: string): string {
        if (!dateInput) return '';
        if (Array.isArray(dateInput)) dateInput = Utils.getDateByArray(dateInput);
        return moment(dateInput).format(format || 'MM/DD/YYYY hh:mm A');
    },

    getDateByArray(dateInput: Date): Date | null {
        if (dateInput && Array.isArray(dateInput)) {
            dateInput = new Date(moment([dateInput[0], dateInput[1] - 1, dateInput[2]]).toDate());
        }

        return dateInput;
    },

    getDate(dateInput: Date | undefined | null): Date | null {
        if (!dateInput) return null;
        if (Array.isArray(dateInput)) dateInput = Utils.getDateByArray(dateInput);
        return moment.utc(dateInput).toDate();
    },

    getESTDate(dateInput: Date | undefined | null, keepLocalTime = true): Date | null {
        if (!dateInput) return null;
        if (Array.isArray(dateInput)) dateInput = Utils.getDateByArray(dateInput);
        return moment(dateInput).tz(EST_TIMEZONE, keepLocalTime).toDate();
    },

    getESTStartDate(dateInput: Date, keepLocalTime = true): Date {
        return moment(dateInput).startOf('day').tz(EST_TIMEZONE, keepLocalTime).toDate();
    },

    getESTEndDate(dateInput: Date, keepLocalTime = true): Date {
        return moment(dateInput).endOf('day').tz(EST_TIMEZONE, keepLocalTime).toDate();
    },

    formatESTDateLong(dateInput: Date | undefined | null): string {
        if (!dateInput) return '';
        if (Array.isArray(dateInput)) dateInput = Utils.getDateByArray(dateInput);
        return moment(dateInput).tz(EST_TIMEZONE).format('MMM DD YYYY');
    },

    formatDate(dateInput: Date | undefined | null, format?: string): string {
        if (!dateInput) return '';
        if (Array.isArray(dateInput)) dateInput = Utils.getDateByArray(dateInput);
        return moment(dateInput).format(format || 'MM/DD/YYYY');
    },

    formatDateUTC(dateInput: Date | undefined | null): string {
        if (!dateInput) return '';
        if (Array.isArray(dateInput)) dateInput = Utils.getDateByArray(dateInput);
        return moment.utc(dateInput).format('MM/DD/YYYY HH:mm:ss');
    },

    getMonthStart(offset: number): Date {
        return moment().add(offset, 'months').startOf('month').startOf('day').toDate();
    },

    getMonthEnd(offset: number): Date {
        return moment().add(offset, 'months').endOf('month').startOf('day').toDate();
    },

    getLastMonthStart(): Date {
        return moment().subtract(1, 'months').startOf('month').toDate();
    },

    getLastMonthEnd(): Date {
        return moment().subtract(1, 'months').endOf('month').toDate();
    },

    localStorage(key: string, defaultValue: string | null): any {
        return localStorage.getItem(key) ?? defaultValue;
    },

    round(num: number, places: number): number {
        if (num === null || isNaN(num)) return num;
        try {
            return Number(num.toFixed(places));
        } catch (error) {}
        return num;
    },

    includeSeparator(prefix: string, separator: string, suffix: string | null | undefined) {
        const resultsSuffix = suffix?.length ? `${separator} ${suffix}` : '';
        return `${prefix} ${resultsSuffix}`;
    },

    formatNumber(value: number | null | undefined, digits: number = 0) {
        if (value === null || value === undefined) return null;
        return value.toLocaleString('en', { minimumFractionDigits: digits });
    },

    formatCurrency(value: number | null | undefined, digits?: number) {
        if (value === null || value === undefined) return null;
        if (digits === undefined) digits = 0;
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: digits,
        }).format(value);
    },

    formatValue(value: any, format: string = '') {
        switch (format) {
            case 'dollar':
                return Utils.formatCurrency(value, 2);

            case 'decimal':
                return Utils.formatNumber(Utils.round(value, 2));

            case 'percent':
                return Utils.formatNumber(Utils.round(value, 2)) + '%';

            default:
                return Utils.formatNumber(value);
        }
    },

    validHex(value: string, alpha?: boolean): boolean {
        const matcher = /^#?([0-9A-F]{3,8})$/i;
        const match = matcher.exec(value);
        const length = match ? match[1].length : 0;

        return (
            length === 3 || // '#rgb' format
            length === 6 || // '#rrggbb' format
            (!!alpha && length === 4) || // '#rgba' format
            (!!alpha && length === 8) // '#rrggbbaa' format
        );
    },

    getMonthList(startYear: number, startMonth: number) {
        const date = new Date(startYear, startMonth - 1); // Months are zero-indexed
        const endDate = new Date();
        const monthList = [];

        while (date <= endDate) {
            const year = date.getFullYear();
            const month = date.getMonth() + 1; // Adjust for zero-indexed months
            const formattedMonth = `${year}-${month.toString().padStart(2, '0')}`;
            monthList.push({
                id: formattedMonth,
                label: moment({ year: year, month: month - 1 }).format('MMMM YYYY'),
            });

            date.setMonth(date.getMonth() + 1); // Move to the next month
        }

        return monthList;
    },

    /**
     * Builds a query string from an object
     *
     * Input:
     * Object {
     *    subject: 'Hello World',
     *    body: 'This is the message.',
     * }
     *
     * Output:
     * ?subject=Hello%20World&body=This%20is%20the%20message.
     */
    createQueryString(queryObject: Record<string, any> = {}): string {
        let queryString: string = Object.keys(queryObject)
            .filter((key: string) => queryObject[key] && !(Array.isArray(queryObject[key]) && !queryObject[key].length))
            .map((key: string) => {
                return Array.isArray(queryObject[key])
                    ? queryObject[key]
                          .map((item: string) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`)
                          .join('&')
                    : `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`;
            })
            .join('&');
        return queryString ? `?${queryString}` : '';
    },
};

export default Utils;
