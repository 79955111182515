import { Table as BaseTable } from '@mui/material';
import { styled, tableCellClasses, tableRowClasses } from '@mui/material';

export const Table = styled(BaseTable)(({ theme }) => ({
    ['&']: {
        borderColor: theme.palette.grey[300],
    },
    [`& .${tableRowClasses.root}`]: {
        backgroundColor: theme.palette.background.default,
    },
    [`& .${tableCellClasses.root}`]: {
        borderColor: theme.palette.grey[300],
        color: theme.palette.text.secondary,
    },
    [`& .${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.background.tableHeader,
        color: theme.palette.text.primary,
        fontWeight: 500,
    },
    // '& .head': {
    //     padding: '5px 0px',
    // },
}));
