import { alpha, createTheme } from '@mui/material';

// const drawerWidth = '258px';
// const defaultTheme = createTheme();
export const navBackground = '#121928';
export const navItemBackgroundHover = 'rgba(77, 102, 166, 0.35)';

const theme = createTheme({
    shape: {
        borderRadius: 8,
    },
    palette: {
        mode: 'light',
        common: {
            black: '#000000',
            white: '#FFFFFF',
        },
        primary: {
            main: '#0E59E7',
            light: '#3E7AEB',
            dark: '#093EA1',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#FFFFFF',
            light: '#B7CEFB',
            dark: '#EEEEEE',
            contrastText: '#0E59E7',
        },
        error: {
            main: '#D32F2F',
            light: '#EF5350',
            dark: '#C62828',
            contrastText: '#FFFFFF',
        },
        warning: {
            main: '#F7BF3B',
            light: '#F7BF3B',
            dark: '#EF6C00',
            contrastText: '#FFFFFF',
        },
        info: {
            main: '#0288D1',
            light: '#03A9F4',
            dark: '#01579B',
            contrastText: '#FFFFFF',
        },
        success: {
            main: '#2E7D32',
            light: '#4CAF50',
            dark: '#1B5E20',
            contrastText: '#FFFFFF',
        },
        grey: {
            '50': '#FAFAFA',
            '100': '#F5F5F5',
            '200': '#EEEEEE',
            '300': '#E0E0E0',
            '400': '#BDBDBD',
            '500': '#9E9E9E',
            '600': '#757575',
            '700': '#616161',
            '800': '#424242',
            '900': '#212121',
            A100: '#F5F5F5',
            A200: '#EEEEEE',
            A400: '#BDBDBD',
            A700: '#616161',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        text: {
            primary: alpha('#000000', 0.87),
            // primary: '#667085',
            // secondary: '#121928',
            secondary: alpha('#000000', 0.6),
            disabled: alpha('#000000', 0.38),
        },
        // divider: '#E6E8F0',
        divider: alpha('#000000', 0.12),
        background: {
            paper: '#FFFFFF',
            paperElevation0: '#FFFFFF',
            paperElevation2: '#FFFFFF',
            default: '#FFFFFF',
            tableHeader: '#FFFFFF',
        },
        action: {
            active: alpha('#000000', 0.56),
            activatedOpacity: 0.56,
            hover: alpha('#000000', 0.04),
            hoverOpacity: 0.04,
            selected: alpha('#000000', 0.08),
            selectedOpacity: 0.08,
            disabled: alpha('#000000', 0.38),
            disabledBackground: alpha('#000000', 0.12),
            disabledOpacity: 0.38,
            focus: alpha('#000000', 0.12),
            focusOpacity: 0.12,
        },
    },
    typography: {
        fontFamily: 'Inter',
        fontSize: 14,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
    },
    components: {
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    // borderTopRightRadius: '8px',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderWidth: '1px',
                    },
                    '&.Mui-focused': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '1px',
                        },
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '& .MuiInputLabel-asterisk': {
                        color: theme.palette.error.main,
                    },
                }),
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.primary.main,
                    '& .MuiIconButton-root': {
                        color: theme.palette.primary.main,
                    },
                }),
                listbox: ({ theme }) => ({
                    '.MuiAutocomplete-option': {
                        color: theme.palette.common.black,

                        '&:hover': {
                            backgroundColor: theme.palette.grey.A100,
                        },
                        '&.Mui-focused': {
                            backgroundColor: theme.palette.grey.A100,
                        },
                        '&[aria-selected="true"]': {
                            backgroundColor: theme.palette.grey.A100,
                        },
                        '&.Mui-focused[aria-selected="true"]': {
                            backgroundColor: theme.palette.grey.A100,
                        },
                    },
                }),
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.primary.main,
                    '& .MuiIconButton-root': {
                        color: theme.palette.primary.main,
                    },
                }),
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
            variants: [
                {
                    props: { variant: 'top' },
                    style: {
                        position: 'fixed',
                        top: 0,
                    },
                },
                {
                    props: { variant: 'bottom' },
                    style: {
                        position: 'fixed',
                        top: 'auto',
                        bottom: 0,
                    },
                },
            ],
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    flexShrink: 0,
                    borderRadius: '8px',
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                },
                paper: ({ ownerState }) => ({
                    borderRadius: '8px',
                }),
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '&.MuiPaper-outlined': {
                        borderColor: theme.palette.grey['300'],
                        borderRadius: 8,
                    },
                }),
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: ({ ownerState }) => {
                    if (ownerState.className?.includes('App-sidebar')) {
                        return {
                            backgroundColor: navBackground,
                            border: 'none',
                        };
                    }
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {},
        },

        MuiTab: {
            styleOverrides: {
                root: ({ theme }) => ({
                    textTransform: 'none',
                    fontWeight: theme.typography.fontWeightMedium,
                }),
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: ({ ownerState }) => {
                    if (ownerState.className?.includes('header')) {
                        return {
                            color: navBackground,
                        };
                    }
                },
                h5: ({ theme }) => ({
                    fontSize: 24,
                    color: theme.palette.text.primary,
                    fontWeight: theme.typography.fontWeightMedium,
                }),
                h6: ({ theme }) => ({
                    fontSize: 20,
                    color: theme.palette.text.primary,
                    fontWeight: theme.typography.fontWeightMedium,
                }),
                subtitle1: ({ theme }) => ({
                    color: alpha('#000000', 0.87),
                    fontWeight: theme.typography.fontWeightMedium,
                }),
                subtitle2: ({ theme }) => ({
                    color: alpha('#000000', 0.6),
                    fontWeight: theme.typography.fontWeightMedium,
                }),
                caption: {
                    color: alpha('#000000', 0.6),
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: ({ theme }) => ({
                    color: theme.palette.primary.contrastText,
                    // backgroundColor: theme.palette.primary.light,
                }),
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: ({ ownerState }) => {
                    if (ownerState.className?.includes('App-toolbar')) {
                        return {
                            backgroundColor: navBackground,
                        };
                    } else {
                        return {
                            backgroundColor: theme.palette.background.default,
                        };
                    }
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '& .MuiSvgIcon-root': {
                        color: alpha('#000000', 0.56),
                    },
                }),
            },
        },
    },
});

export default theme;

// Augment the Paper module to allow for the 'top' and 'bottom' AppBar variants
declare module '@mui/material/Paper' {
    interface PaperPropsVariantOverrides {
        top: true;
        bottom: true;
    }
}

// Augment the styles module to handle custom colors
declare module '@mui/material/styles' {
    interface TypeBackground {
        tableHeader: string;
        paperElevation0: string;
        paperElevation2: string;
    }
}
