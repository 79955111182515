import { Agency, AgencyFirstPartyDealer } from '../../types/Agency';
import { CDTextField } from '../../components/CDTextField';
import { ChangeEvent, useState } from 'react';
import { Button, Grid } from '@mui/material';
import AgencyFirstPartyDealerDataGridItemActions from './AgencyFirstPartyDealerDataGridItemActions';
import AgencyFirstPartyDealerDialog from './AgencyFirstPartyDealerDialog';
import CDGrid from '../../components/CDGrid';
import Column from '../../components/Column';
import CRMDataUploadRequirementAlert from '../../pages/dealers/CRMDataUploadRequirementAlert';
import Row from '../../components/Row';

type FirstPartyDataIntegrationProps = {
    agency: Agency;
    onChange(value: Partial<Agency>): void;
};

export default function FirstPartyDataIntegration(props: FirstPartyDataIntegrationProps) {
    const { agency, onChange } = props;

    const [agencyFirstPartyDealer, setAgencyFirstPartyDealer] = useState<AgencyFirstPartyDealer | null>(null);
    const [agencyFirstPartyDealerDialogOpen, setAgencyFirstPartyDealerDialogOpen] = useState<boolean>(false);

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        let agency = { [event.target.name]: event.target.value };
        onChange(agency);
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <CDTextField
                        fullWidth
                        label="FTP User"
                        name="firstPartyDataFtpUser"
                        autoFocus={true}
                        onChange={handleChange}
                        value={agency.firstPartyDataFtpUser}
                    />
                </Grid>
                <Grid item xs={8} />
                <Grid item xs={4}>
                    <CDTextField
                        fullWidth
                        label="Error Notification Email"
                        name="firstPartyDataFailureEmail"
                        autoFocus={true}
                        onChange={handleChange}
                        value={agency.firstPartyDataFailureEmail}
                    />
                </Grid>
                <Grid item xs={8} />
                <Grid item xs={4}>
                    <CDTextField
                        fullWidth
                        label="Success Notification Email"
                        name="firstPartyDataResultsEmail"
                        autoFocus={true}
                        onChange={handleChange}
                        value={agency.firstPartyDataResultsEmail}
                    />
                </Grid>
                <Grid item xs={8} />

                <Grid item md={6}>
                    <CRMDataUploadRequirementAlert />
                </Grid>

                <Grid item xs={6} />

                <Grid item xs={6}>
                    <Column>
                        <CDGrid
                            rowHeight={70}
                            headerHeight={70}
                            columns={[
                                {
                                    field: 'dealer',
                                    headerName: 'Advertiser',
                                    width: 300,
                                    valueGetter: (params) => params.value?.dealerName,
                                },
                                {
                                    field: 'dealerCode',
                                    headerName: 'Agency Advertiser Code',
                                    width: 240,
                                },
                                {
                                    flex: 0.5,
                                    width: 100,
                                    field: 'actions',
                                    headerName: 'Actions',
                                    headerAlign: 'right',
                                    align: 'right',
                                    type: 'actions',
                                    renderCell: (params) => {
                                        return (
                                            <AgencyFirstPartyDealerDataGridItemActions
                                                agency={agency}
                                                agencyFirstPartyDealer={params.row}
                                                onEdit={(_agencyFirstPartyDealer: AgencyFirstPartyDealer) => {
                                                    setAgencyFirstPartyDealer(_agencyFirstPartyDealer);
                                                    setAgencyFirstPartyDealerDialogOpen(true);
                                                }}
                                                onDelete={(
                                                    _agencyFirstPartyDealer: AgencyFirstPartyDealer,
                                                    _agency: Agency
                                                ) => {
                                                    onChange({
                                                        agencyFirstPartyDealers: [..._agency.agencyFirstPartyDealers],
                                                    });
                                                }}
                                            />
                                        );
                                    },
                                },
                            ]}
                            rows={agency.agencyFirstPartyDealers ? agency.agencyFirstPartyDealers : []}
                        />

                        <Row>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setAgencyFirstPartyDealer(null);
                                    setAgencyFirstPartyDealerDialogOpen(true);
                                }}
                            >
                                Add Agency First-Party Dealer
                            </Button>
                        </Row>
                    </Column>

                    {agencyFirstPartyDealerDialogOpen && (
                        <AgencyFirstPartyDealerDialog
                            agency={agency}
                            agencyFirstPartyDealer={agencyFirstPartyDealer}
                            open={agencyFirstPartyDealerDialogOpen}
                            onClose={() => {
                                setAgencyFirstPartyDealer(null);
                                setAgencyFirstPartyDealerDialogOpen(false);
                            }}
                            onSave={(agencyFirstPartyDealer: AgencyFirstPartyDealer, _agency: Agency) => {
                                onChange({
                                    agencyFirstPartyDealers: [..._agency.agencyFirstPartyDealers],
                                });
                            }}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    );
}
