import { useState } from 'react';
import { Alert, AlertTitle, Box, Button, Link } from '@mui/material';
import CRMDataUploadRequirementDialog from './CRMDataUploadRequirementDialog';
import Row from '../../components/Row';

export default function CRMDataUploadRequirementAlert() {
    const [requirementDialogOpen, setRequirementDialogOpen] = useState<boolean>(false);

    return (
        <>
            <Alert
                severity="info"
                sx={{
                    '& .MuiAlert-message': {
                        width: '100%',
                    },
                }}
            >
                <Row justifyContent="space-between" alignItems="center">
                    <AlertTitle>CSV Data & Format Requirements</AlertTitle>
                </Row>
                Please review these requirements before selecting your file. Both the quality and completeness of the
                data will significantly impact the rate at which events match back to an Amazon account.
                <Box sx={{ mt: 0.5 }}>
                    <Button color="info" variant="text" onClick={() => setRequirementDialogOpen(true)}>
                        View Requirements
                    </Button>

                    <Button
                        component={Link}
                        color="info"
                        variant="text"
                        href="/resources/Cognition CSV Template.csv"
                        target="new"
                    >
                        Download CSV Template
                    </Button>
                </Box>
            </Alert>

            {requirementDialogOpen ? (
                <CRMDataUploadRequirementDialog
                    open={requirementDialogOpen}
                    onClose={() => setRequirementDialogOpen(false)}
                />
            ) : (
                ''
            )}
        </>
    );
}
